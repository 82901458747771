.toggle {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 2.2rem;
    border-radius: 40%;
    background-color: var(--background-color);
    cursor: pointer;
}

.toggle_text {
    font: var(--font-3-bold);
    color: white;
    padding: 0.5rem;
}

.toggle_thumb {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--background-color-dark);
    transition: all 0.2s ease-in-out;
}

.toggle_thumb_right {
    transform: translate(1.8rem, 0);
}

.toggle_icon {
    color: var(--background-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
}