.container {
    margin: 2rem;
    background-color: var(--background-color-secondary);
    border-radius: 1rem;
    padding: 1rem;
    min-width: 60vw;
}

.title {
    font: var(--font-1);
    color: var(--text-color);
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font: var(--font-2);
}

.selectionList {
    list-style-type: disc;
    margin-left: 2rem;
    margin-top: 0.5rem;
    font: var(--font-2);
    color: var(--text-color);
    text-align: left;
    padding: 1rem;
}

.selectionList li {
    margin-bottom: 0.5rem;
}

.description {
    font: var(--font-3);
    color: var(--text-color);
    text-align: left;
}

.contentContainer {
    margin-top: 1rem;
}

.link {
    padding: 1rem;
    font: var(--font-3);
    color: var(--secondary-text-color);
    text-decoration: none;
}

.linkContainer {
    margin-bottom: 1rem;
}

.link:hover {
    text-decoration: underline;
}

.noSelection {
    font: var(--font-1);
    color: var(--text-color);
    text-align: center;
    margin-top: 2rem;
}

.goBackButton {
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: var(--background-color-secondary);
    font: var(--font-3);
    color: var(--text-color);
    text-decoration: none;
    border: 1px solid var(--secondary-text-color);
    border-radius: 0.5rem;
}

.goBackButton:hover {
    background-color: var(--background-color);
    color: var(--secondary-text-color);
    border: 1px solid var(--text-color);
    transition: 0.2s;
}