/* Define the theme */
:root {
  --font-size: 16px;
  --font-family: 'Lora', sans-serif;
  --font-1: 700 2.2rem var(--font-family);
  --font-2: 400 2rem var(--font-family);
  --font-3-bold: 700 1.5rem var(--font-family);
  --font-3: 200 1.5rem var(--font-family);
  --background-color: aliceblue;
  --background-color-secondary: #fff;
  --background-color-dark: #303030;
  --color-primary: #0277BD;
  --color-secondary: #29B6F6;
  --color-tertiary: #B2EBF2;
  --text-color: #000;
  --secondary-text-color: #0D47A1;
  --text-contrast-color: #fff;
  --secondary-text-contrast-color: #90CAF9;
  --category-select-text-color: : #fafafa;
}

@media screen and (max-width: 768px) and (min-width: 672px) {
  :root {
    --font-size: 12px;
  }
}

@media screen and (max-width: 671px) {
  :root {
    --font-size: 8px;
  }
}


html {
  font-size: var(--font-size);
  overflow-x: hidden;
}

[data-theme="dark"] {
  --background-color: #303030;
  --background-color-secondary: #424242;
  --background-color-dark: aliceblue;
  --color-primary: #01579B;
  --color-secondary: #0288D1;
  --color-tertiary: #618eb4;
  --text-contrast-color: #000;
  --secondary-text-contrast-color: #0D47A1;
  --text-color: #fff;
  --secondary-text-color: #90CAF9;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100%;
  background-color: var(--background-color);
  padding-bottom: 400px;
}

.welcome {
  font: var(--font-2);
  font-weight: 800;
  color: var(--text-color);
  margin: 1rem;
  margin-bottom: 0;
}

.welcomeSubheader {
  font: var(--font-3-bold);
  color: var(--text-color);
  margin-bottom: 2rem;
}

.mental-health-blurb {
  font: var(--font-3);
  color: var(--text-color);
  margin: 2rem;
}

.mental-health-blurb b {
  font: var(--font-3-bold);
  color: var(--text-color);
}

.mentalHealthMatters {
  font: var(--font-1);
  color: var(--text-color);
  margin: 2rem;
}

.paperLinkHeader {
  font: var(--font-3);
  color: var(--text-color);
  margin: 2rem;
}

.paperLink {
  font: var(--font-3-bold);
  color: var(--secondary-text-color);
  text-decoration: none;
}

.paperLink:hover {
  text-decoration: underline;
}

.incrementButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 2rem;
}

.incrementButton {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 1rem;
  height: 4rem;
  align-items: center;
  width: 10rem;
  justify-content: center;
  font: var(--font-3);
  color: var(--text-color);
  background-color: var(--background-color-secondary);
  border: 1px solid var(--color-tertiary);
  border-radius: 10rem;
}

.incrementButton:hover {
  background-color: var(--color-secondary);
  color: white;
  cursor: pointer;
  transition: 0.5s;
}

.incButtonArrow {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}