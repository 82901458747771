:root {
    --header-height: 8rem;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: var(--color-primary);
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: var(--header-height);
    color: white;
}

.title {
    font: var(--font-1);
    margin: 2rem;
    margin-left: auto;
    cursor: pointer;
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-left: 4rem;
    margin-right: auto;
}

.categorySelect {
    margin-left: auto;
    margin-right: 5rem;
    padding: 5rem;
}

.spacer {
    width: 100%;
    height: var(--header-height);
}