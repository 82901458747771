.title {
    font: var(--font-1);
    margin: 2rem;
    color: var(--text-color);
}

.description {
    font: var(--font-2);
    color: var(--secondary-text-color);
}

.container {
    margin: 2rem;
    background-color: var(--background-color-secondary);
    border-radius: 1rem;
    padding: 1rem;
}