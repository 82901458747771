.categoryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.categoryContainer:hover {
    cursor: pointer;
}

.category {
    position: absolute;
    color: var(--category-select-text-color);
    font: var(--font-2);
    border-radius: 50%;
    transition: 0.5s;
}

.category:hover {
    cursor: pointer;
    color: white;
    transition: 0.5s;
}

.center {
    position: absolute;
    color: var(--text-color);
    font: var(--font-1);
    background-color: var(--background-color-secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center:hover {
    cursor: pointer;
    color: white;
    background-color: var(--color-primary);
    transition: 0.5s;
}

.categoryTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 1s;
}
