.suggestionContainer {
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: var(--background-color-secondary);
    border-top: none;
    overflow-y: auto;
    text-align: left;
    padding: 2rem;
    border-radius: 1rem;
}

.suggestionCheckbox {
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem;
    border-color: var(--color-primary);
}

.suggestionCheckbox:checked {
    background-color: var(--color-secondary) !important;
    border-color: var(--color-primary);
}

.suggestion {
    cursor: pointer;
    padding: 0.5rem;
    font: var(--font-3);
    padding: 1rem;
    color: var(--text-color);
    border-radius: 1rem;
}

.suggestion:hover {
    background-color: var(--color-tertiary);
}

.selected {
    background-color: var(--color-tertiary);
}

.linkContainer {
    border-radius: 2rem;
    padding: 1rem;
    margin-top: 1rem;
    background-color: var(--background-color);
}

.linkHeader {
    font: var(--font-3);
    color: var(--text-color);
    margin-bottom: 1rem;
}

.linkHeader span {
    text-transform: lowercase;
}

.link {
    font: var(--font-3);
    font-size: 1.3rem;
    color: var(--secondary-text-color);
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}